$primary_bg: #f3f7ff;
$primary_color: #1a82ff;
$primary_color__hover: #0265db;
$primary_color__active: #1a39ff;
$primary_font: #282828;
$secondary_font: #798da2;
$main_color: #fff;

$primary_font_size: 16px;

p, h1, h2,
h3, h4, h5,
h6, ul {
   margin: 0;
   padding: 0;
   list-style: none;
}

.text-decor-none:hover {
   text-decoration: none;
}

.flex {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
}

.flex-wrap {
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
}

.j-sb {
   -webkit-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between;
}

.j-se {
   justify-content: space-evenly;
}

.j-center {
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
}

.flex-align-center {
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
}

.flex-align-bottom {
   -webkit-box-align: end;
   -ms-flex-align: end;
   align-items: flex-end;
}

.flex-direction-col {
   flex-direction: column;
}

.transition {
   -webkit-transition: all 0.3s;
   transition: all 0.3s;
}

.main-btn {
   font-size: $primary_font_size - 1;
   background: $primary_color;
   color: $main_color;
   border-radius: 50px;
   padding: 13px 0;
   outline: none;
   border: none;

   &:hover:enabled {
      cursor: pointer;
      background: $primary_color__hover;
   }

   &:active:enabled {
      background: $primary_color__active;
   }

   &:disabled {
      opacity: 0.6;
   }
}

.header-language__box__btn {
   color: $secondary_font;
   font-size: $primary_font_size + 1px;
   background: none;
   outline: none;
   border: none;
   cursor: pointer;

   &:hover {
      color: #576675;
   }
}

.header-language-dropdown-item {
   min-width: auto !important;
   color: $secondary_font;
   background: none;
   padding: 0;
   -webkit-box-shadow: none;
   box-shadow: none;
   left: 6px !important;

   ul {
      li {
         cursor: pointer;
         margin-bottom: 8px;

         &:hover {
            color: #576675;
         }
      }
   }
}

.text-title {
   font-weight: 700;
}

.text-desc {
   line-height: 1.8;
   color: $secondary_font;
   margin: 50px 0 40px;
}

.info-position-r {
   width: 40%;
}

.info-position-l {
   width: 37%;
}

.full-width {
   width: 100%;
}

.responsive-header-language,
.responsive-header-info__call {
   display: none;
}

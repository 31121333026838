@import "partials";

@media (max-width: 899px) {
   .info-position-r {
      width: 52%;
   }

   .img-position-adaptive {
      width: 40%;
   }

   .communicate-box {
      .img-position-adaptive {
         width: 35%;
      }
   }

   .chat-section {
      .chat-container {
         .chat-success-width {
            width: 70%;
         }
      }
   }

   .communicate-overlay-users {
      img {
         &:nth-child(3),
         &:nth-child(5) {
            display: none;
         }
      }
   }
}

@media (max-width: 722px) {
   .main-btn {
      padding: 10px 0;
   }

   .communicate-overlay-users {
      img {
         &:nth-child(2) {
            display: none;
         }
      }
   }

   .header-info__sign-in,
   .picture-box__btn {
      width: 100% !important;
   }
}

@media (max-width: 622px) {
   .chat-box-messages {
      height: 470px !important;
   }

   .communicate-section {
      padding-top: 100px !important;
   }

   .find-section {
      margin-top: 60px !important;

      .stock {
         padding-bottom: 90px !important;
         width: 87% !important;

         .stock-box {
            bottom: -35%;
            padding: 20px 15px 30px !important;

            .text-desc {
               margin: 10px 0 20px;
            }

            .stock-btn {
               width: 80% !important;
            }
         }
      }
   }

   .footer-box {
      .footer-change-lang {
         padding: 0;
         color: $secondary_font;
         font-size: $primary_font_size + 1px !important;
         background: none;
         outline: none;
         border: none;
         cursor: pointer;
      }

      .header-language-dropdown {
         left: 0 !important;
      }
   }
}

@media (max-width: 523px) {
   h1 {
      font-size: 1.7em;
   }

   .text-desc,
   .header-info__sign-in {
      font-size: $primary_font_size - 2px !important;
   }

   .responsive-header-info__call {
      font-size: $primary_font_size - 5px !important;
   }

   .wrapper {
      padding: 0 15px;
   }

   .text-desc {
      color: $secondary_font;
      margin: 30px 0;
   }

   header {
      .header-logo {
         .header-language {
            .header-logo__img {
               width: 55px !important;
               margin-right: 5px !important;
            }

            .responsive-header-language {
               display: block;
            }

            .header-language__box {
               display: none;
            }
         }
      }

      .header-download {
         a {
            font-size: $primary_font_size - 3px !important;
            padding-left: 14px !important;
            padding-right: 4px !important;

            .header-download__icon {
               width: 43px !important;
            }
         }
      }

      .header-body {
         .header-info {
            width: 100%;

            .responsive-header-info__call {
               display: block;
               width: 100%;
               background: #54c583;
               border-radius: 50px;
               padding: 12px 0;
               color: #fff;
               border: none;
               font-size: $primary_font_size - 3px !important;
               margin-bottom: 30px;
               position: relative;

               &:hover:enabled {
                  cursor: pointer;
                  background: #3ba366;
               }

               a {
                  color: inherit;
               }

               i {
                  position: absolute;
                  left: 5px;
                  background: #6ee59f;
                  border-radius: 50%;
                  padding: 6.5px 8px 5.5px;
                  top: 6px;
               }
            }
         }

         .header-img {
            display: none;
         }
      }
   }

   footer {
      padding-bottom: 30px !important;
   }

   .footer-box-lang {
      .header-language-dropdown {
         left: 55px !important;
         top: 10px !important;
      }
   }

   .footer-box ul {
      margin-bottom: 40px;
   }

   .footer-box ul li {
      margin-bottom: 10px;
   }

   .find-box,
   .picture-box,
   .chat-container {
      flex-direction: column;
   }

   .communicate-box,
   .footer-box,
   .footer-box ul {
      display: block;
   }

   .picture-box__img {
      margin-top: 70px;
      order: 1;
   }

   .chat-box {
      margin-top: 35px;
   }

   .chat-section {
      .chat-container {
         .chat-success-width {
            margin-bottom: 0;

            &:first-child {
               text-align: left;
            }
         }
      }
   }

   .picture-box__img,
   .picture-box__info,
   .find-box__info,
   .find-box__img,
   .communicate-box__info,
   .communicate-box__img,
   .chat-box,
   .chat-info-box {
      width: 100% !important;
   }

   .communicate-box__img {
      img {
         width: 100%;
      }
   }

   .communicate-overlay-users {
      img {
         &:nth-child(4),
         &:nth-child(5),
         &:nth-child(6) {
            display: none;
         }

         &:nth-child(2) {
            display: block;
            right: 60% !important;
            top: 280px !important;
         }

         &:nth-child(3) {
            display: block;
            right: 25% !important;
            top: 345px !important;
         }
      }
   }

   .communicate-box__info {
      margin-bottom: 200px;
   }

   .information-wrapper {
      .information-body {
         .information-terms-box {
            .information-term-box__item {
               margin-left: 0 !important;
            }
         }
      }
   }

   .information-footer {
      padding: 0 25px;
   }

   .hide-show-btn {
      right: 0;
      top: 4px;
   }
}

@import "partials";

.lds-ellipsis {
   display: inline-block;
   position: relative;
   width: 64px;
   height: 30px;

   div {
      position: absolute;
      top: 11px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $primary_color;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);

      &:nth-child(1) {
         left: 6px;
         animation: lds-ellipsis1 0.6s infinite;
      }

      &:nth-child(2) {
         left: 6px;
         animation: lds-ellipsis2 0.6s infinite;
      }

      &:nth-child(3) {
         left: 26px;
         animation: lds-ellipsis2 0.6s infinite;
      }
      
      &:nth-child(4) {
         left: 45px;
         animation: lds-ellipsis3 0.6s infinite;
      }
   }
}

@keyframes lds-ellipsis1 {
   0% {
      transform: scale(0);
   }
   100% {
      transform: scale(1);
   }
}
@keyframes lds-ellipsis3 {
   0% {
      transform: scale(1);
   }
   100% {
      transform: scale(0);
   }
}
@keyframes lds-ellipsis2 {
   0% {
      transform: translate(0, 0);
   }
   100% {
      transform: translate(19px, 0);
   }
}

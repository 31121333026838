@import "partials";
@import "modalTry";
@import "spinner";
@import "media";


.ReactModal__Overlay {
   background-color: rgba(0, 0, 0, 0.6) !important;
   padding: 0;
}

.home-page-container {
   overflow: hidden !important;
}

body {
   font-family: 'Open Sans', sans-serif;
   font-size: $primary_font_size;
   color: $primary_font;
   margin: 0;
}

.wrapper {
   max-width: 1170px;
   margin: 0 auto;
   padding: 0 20px;
}

header {
   background: $primary_bg;

   .header-wave {
      padding-top: 100px;

      .cls-1 {
         fill: $main_color;
      }
   }

   .header-logo {
      padding-top: 40px;

      .header-language {
         .header-logo__img {
            width: 65px;
            margin-right: 30px;
         }
      }
   }

   .header-download {
      a {
         -webkit-box-sizing: border-box;
         box-sizing: border-box;
         background: #d4e7ff;
         border-radius: 70px;
         color: #798da2;
         padding: 6px 20px;
         font-size: $primary_font_size - 1.5px;
         width: 100%;

         &:hover {
            background: #c2dcff;
         }

         .header-download__icon {
            width: 52px;
            margin-right: 10px;
         }

         .header-download__desc {
            width: 100%;
            line-height: 1.4;

            span {
               display: block;
            }
         }
      }
   }

   .header-body {
      margin-top: 35px;

      .header-info {
         margin-top: 15%;
      }

      .header-info__sign-in {
         width: 70%;
      }

      .header-img {
         margin-top: 40px;
      }
   }
}

.chat-section {
   margin-bottom: 80px;

   .chat-container {
      .chat-box {
         position: relative;
         border-radius: 10px;
         overflow: hidden;
         border: 1px solid $primary_color;

         .toggle-chat {
            position: absolute;
            border: none;
            outline: none;
            background: none;
            cursor: pointer;
            right: -5px;
            top: -5px;
            font-size: $primary_font_size + 8px;
            color: $primary_color;
         }

         .chat-box-online-users {
            text-align: right;
            margin: 0 10px 20px;
         }

         .chat-box-messages {
            height: 600px;
            overflow: auto;

            .my-message {
               display: none;
            }

            .chat-box-messages__item {
               font-size: $primary_font_size - 2px;
               margin-bottom: 20px;
               padding-right: 6px;

               .chat-box-avatar {
                  margin: 0 4px;
                  border-radius: 50%;
                  overflow: hidden;
                  width: 25px;
                  min-width: 25px;

                  img {
                     width: 100%;
                  }
               }

               .chat-box-message {
                  position: relative;
                  background: #fce76c;
                  color: $primary_font;
                  border-radius: 7px 7px 7px 0;
                  padding: 10px 35px 30px;
                  -webkit-box-sizing: border-box;
                  box-sizing: border-box;
                  overflow: hidden;

                  .chat-box-message__author {
                     position: absolute;
                     font-size: $primary_font_size - 5px;
                     display: block;
                     width: 100%;
                     bottom: 0;
                     left: 0;
                     padding: 4px 10px;
                     color: $main_color;
                     background: #d9c658;
                  }
               }
            }

            .chat-box-my-message {
               justify-content: flex-end;

               .my-message {
                  display: block;
               }

               .other-message {
                  display: none;
               }

               .chat-box-message {
                  border-radius: 7px 7px 0 7px;
                  background: $primary_color;
                  color: $main_color;

                  .chat-box-message__author {
                     background: #499bfe;
                  }
               }

               .chat-box-date {
                  margin-right: 7px;
               }
            }

            .chat-box-date {
               font-size: $primary_font_size - 5px;
               color: $secondary_font;
               margin-left: 7px;
            }

            .chat-typing-box {
               position: relative;
               height: 20px;
               padding-bottom: 10px;

               .chat-typing {
                  font-size: $primary_font_size - 3px !important;
                  top: -5px;
                  position: absolute;
                  margin: 0 0 0 32px;
               }
            }
         }

         .chat-container-send {
            position: relative;

            .chat-box-send {
               border: none;
               outline: none;
               background: #f3f7ff;
               padding: 20px 62px 20px 30px;
               color: #84888c;
               -webkit-box-sizing: border-box;
               box-sizing: border-box;
               display: block;
               width: 100%;
            }

            .chat-box-send-btn {
               border: none !important;
               position: absolute;
               top: 10px;
               right: 15px;
               background: $primary_color;
               border-radius: 50%;
               padding: 9px 12px 11px;
               color: $main_color;
               cursor: pointer;

               &:hover {
                  background: $primary_color__hover;
               }

               &:active {
                  outline: none !important;
                  background: $primary_color__active;
               }
            }
         }
      }
   }

   .chat-logout {
      .chat-logout-btn {
         width: 170px;
      }
   }

   .chat-sign-in {
      margin-top: 15px;

      .chat-sign-in__field__phone {
         &::before {
            content: '+';
            display: block;
            z-index: 999;
            position: absolute;
            left: 10px;
            top: 8px;
         }
      }

      .chat-sign-in__field {
         position: relative;
         margin-bottom: 20px;

         input {
            font-size: 15px;
            border-left: 0;
            border-top: 0;
            border-right: 0;
            padding-left: 20px;
         }
      }

      .chat-sign-in__submit {
         .main-btn {
            width: 100%;
         }
      }

      .chat-sign-in__notification {
         text-align: center;
         height: 40px;
         margin-top: 15px;

         .text-desc {
            font-size: $primary_font_size + 1px !important;
         }
      }
   }

   .chat-info-box {
      .text-desc {
         margin-bottom: 0;
      }
   }

   .hide-show-btn {
      right: 0;
      top: 11px;
   }

   .chat-success-width {
      width: 55%;
      margin-bottom: 40px;

      &:first-child {
         text-align: center;
      }
   }
}

.picture-section {
   .picture-box__btn {
      width: 45%;
   }
}

.find-section {
   margin-top: 130px;

   .stock {
      width: 500px;
      margin: 0 auto;
      position: relative;
      padding-bottom: 250px;

      .stock-box {
         background: $main_color;
         padding: 20px 50px 30px;
         -webkit-box-sizing: border-box;
         box-sizing: border-box;
         text-align: center;
         position: absolute;
         width: 100%;
         border-radius: 40px;
         bottom: -50%;
         -webkit-box-shadow: 0 0 37px -14px rgba(0, 0, 0, 0.75);
         box-shadow: 0 0 37px -14px rgba(0, 0, 0, 0.75);

         h2 {
            color: $primary_color;
         }

         .text-desc {
            margin: 25px 0 35px;
         }

         .stock-btn {
            width: 50%;
         }
      }
   }
}

.communicate-section {
   background: $primary_bg;
   padding-top: 160px;

   .communicate-overlay-users {
      padding-top: 70px;
      position: relative;

      img {
         position: absolute;
         bottom: 0;

         &:first-child {
            right: 0;
            top: 0;
         }

         &:nth-child(2) {
            right: 50%;
            top: 435px;
         }

         &:nth-child(3) {
            right: 58%;
            top: 580px;
         }

         &:nth-child(4) {
            left: 25%;
            top: 90px;
         }

         &:nth-child(5) {
            left: 0;
            top: 520px;
         }

         &:nth-child(6) {
            left: 6%;
            top: 80px;
         }
      }
   }

   .communicate-box {
      margin-top: 40px;

      .communicate-box__info {
         margin-top: 15%;
      }

      .communicate-box__img {
         margin-bottom: -10px;
      }
   }
}

footer {
   padding: 50px 0;

   p {
      color: #c6c5c5;
      margin: 0;
   }

   ul {
      li {
         color: $secondary_font;
         margin-right: 45px;

         a {
            color: $secondary_font;

            &:hover {

            }
         }

         &:last-child {
            margin: 0;
         }
      }
   }

   .footer-change-lang {
      padding: 0;
      color: $secondary_font;
      font-size: $primary_font_size + 1px !important;
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
   }

   .header-language-dropdown {
      left: 0 !important;
   }
}

.information-wrapper {
   max-width: 830px;
   margin: 0 auto;

   .information-header {
      position: relative;
      padding: 20px 10px;
      box-sizing: border-box;
      text-align: center;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .15);
      color: #c6c5c5;

      .information-link {
         position: absolute;
         left: 20px;
         top: 17px;
         font-size: $primary_font_size + 6px;
      }
   }

   .information-body {
      background: #fcfcfc;
      padding: 10px 25px 0;
   }
}

.redirect-config-spinner {
   position: absolute;
   top: 47%;
   left: 47%;
}

.error-indicator,
.not-found {
   position: absolute;
   top: 45%;
   left: 39%;
   color: $primary_color;
}

.hide-show-btn {
   background: none;
   position: absolute;
   right: 10.5%;
   top: 4px;
   font-size: $primary_font_size + 4px;
   outline: none;
   border: none;

   i {
      cursor: pointer;
   }
}

@import "partials";

.modal-try {
   padding-top: 40px;
   margin: 0 30px 30px;
   max-width: 404px;
   box-sizing: border-box;
   position: relative;

   .modal-close {
      position: absolute;
      top: 8px;
      right: -13px;
      color: $secondary_font;
      cursor: pointer;
      font-size: 18px;

      &:hover {
         color: $primary_color;
      }
   }

   h2 {
      font-size: $primary_font_size + 8px;
      margin-bottom: 30px;
      margin-top: 0;
   }

   .field {
      position: relative;
      margin-bottom: 30px;

      label {
         position: relative;

         i {
            position: absolute;
            color: #ff9500;
            top: 4px;
            left: 20px;
         }

         input {
            background: #eee;
            padding: 10px 35px 10px 60px;
            outline: none;
            border: none;
            border-radius: 5px;
            width: 80%;
            box-sizing: border-box;
         }

         input::-webkit-outer-spin-button,
         input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0 !important;
         }
      }

      .modal-phone {
         &::before {
            content: '+';
            display: block;
            z-index: 999;
            position: absolute;
            left: 15.5%;
            top: 0;
         }
      }

      .modal-password {
         input {
            padding-left: 50px;
         }
      }
   }

   .agree-box {
      color: $primary_color;
      padding-top: 30px;
      border-top: 1px solid #ececec;

      .agree-field {
         margin-right: 20px;

         i {
            font-size: 26px;
            cursor: pointer;
         }

         input[type="checkbox"] {
            margin-right: 10px;
         }
      }

      p {
         a {
            text-decoration: underline;
         }
      }
   }

   .modal-submit {
      margin: 30px 0 10px;

      .main-btn {
         width: 70%;
         border-radius: 5px;

         &:disabled {
            opacity: 0.5;
         }
      }
   }

   .modal-notification {
      height: 30px;
      margin: 10px auto;
      font-size: $primary_font_size - 3px;
   }

   .forgot-pass {
      button {
         background: none;
         outline: none;
         border: none;
         color: $primary_color;
         font-size: $primary_font_size + 1px;
         cursor: pointer;

         &:hover {
            text-decoration: underline;
         }
      }
   }
}

.uk-modal-dialog {
   width: 490px;
}

@media (max-width: 523px) {
   .modal-try {
      h2 {
         font-size: 18px !important;
      }

      .field {
         position: relative;
         margin-bottom: 15px;

         label {
            position: static;

            input {
               display: block;
               background: none;
               width: 100% !important;
               border-radius: 0;
               padding-left: 58px;
               border-bottom: 1px solid #ececec;
            }

            i {
               top: 10px;
               left: 15px;
            }
         }

         .modal-phone {
            &::before {
               left: 48px !important;
               top: 4.5px !important;
            }
         }
      }

      .agree-box {
         width: 100%;
         font-size: $primary_font_size - 2px;
         margin: 0 auto;
         padding-top: 5px;
         border-top: none;
         text-align: left;
      }

      .modal-submit {
         .main-btn {
            width: 80%;
         }
      }
   }
}
